require('bootstrap');
require('jquery/src/jquery');

$('body').on('gsap.loaded', function () { 
    
    var menuMaster = new TimelineMax({ paused: true });

    menuMaster
        .to(".menu-bg-colour", 1, { ease: Power3.easeIn, bottom: 0 })
        .to(".menu-logo", 1, { ease: Power3.easeIn, bottom: 0 }, "-=1")
        .to(".main-logo-light", 0, { display: 'block' })
        .to(".menu-overlay", 0, { display: 'block', autoAlpha: 1 })
        .to(".menu-mask", 0.5, { right:0 })
        .to(".menu-mask-text", 0, { autoAlpha: 1 })
        //.to(".hover-line", 0, { autoAlpha: 1 })
        .to(".menu-mask", 1, { ease: Power1.easeOut,left: "100%" })
        .to(".menu-detail-letter-mask", 1, { left: "100%" }, "-=1");

    $("#menu-open, .menu-trigger").click(function (e) {
        e.preventDefault();
        if (menuMaster.progress() === 0) {
            setTimeout(function () {
                $('#menu-open').addClass('is-active');
            }, 750);
            menuMaster.play();  
        } else {
            menuMaster.eventCallback("onReverseComplete", hamburgerClose);
            menuMaster.reverse();
        } 
               
    });

    function hamburgerClose() {
        $('#menu-open').removeClass('is-active');
    }


    $('a.menu-mask-wrap, a.header-logo, .desktop-menu a').click(function (e) {
        e.preventDefault();
        newLocation = this.href;
        $('body').fadeOut();
        newPage();
    });

    function newPage() {
        window.location = newLocation;
    }


     
        
    /////// client list hover state 
    $(".project-list-link").hover(over, out);
    var projectListHovers= [];

    $(".project-list-link").each(function(item){
        var projectListHover = new TimelineMax({ paused: true });

        projectListHover
            .to(`.project-list-link#project-list-item-${item} .project-mask-bg`, 0.5, { autoAlpha: 0.8 })
            .to(`.project-list-link#project-list-item-${item} .project-link-mask`, 0.25, { right: "-5px" }, "-=0.25")
            .to(`.project-list-link#project-list-item-${item} .project-list-btn`, 0, { autoAlpha: 1 })
            .to(`.project-list-link#project-list-item-${item} .project-link-mask`, 0.5, { ease: Power1.easeOut, left: "105%" });
        projectListHovers.push(projectListHover);
    });

    function getProjectOffset(item){
        return parseInt($(item).attr('id').substr(18));
    }

    function over() {
        var projectListHover = projectListHovers[getProjectOffset(this)];

        projectListHover.play();
    }

    function out() {
        var projectListHover = projectListHovers[getProjectOffset(this)];

        projectListHover.reverse();
    }


    ////////// CLIENT LIST FILTERING
    
    var selectedClass = "";
    $(".cat-toggle-a").click(function (e) {
        e.preventDefault();
        selectedClass = $(this).attr("data-filter");
        $("#project-list").fadeTo(100, 0);
        $(".project-list-item").not("." + selectedClass).fadeOut();
        $(".cat-toggle-a").removeClass('active');
        $(this).addClass('active');
        setTimeout(function () {
            $("." + selectedClass).fadeIn();
            $("#project-list").fadeTo(300, 1);
        }, 300);
    });

    

    ///// Testimonial Words Parallax

    $('.testimonial-block').each(function () {
        var controller = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: "onEnter", duration: "150%" } });
        var currentTestimonial = $(this);
        var scene = new ScrollMagic.Scene({ triggerElement: currentTestimonial[0] })
            .setTween(currentTestimonial.find('.faint-text'), { y: "-200%", ease: Linear.easeNone })
            //.addIndicators()
            .addTo(controller);
    });


    ///// Client Slider
    var ClientSlider = $('#client-slider');
    if (ClientSlider.length > 0) {
        var slides = ClientSlider.find('.slide'),
        tl = new TimelineLite({
            paused: true
        });

        for (var i = 0; i < slides.length; i++) {
            //if not the last slide
            if (i != slides.length - 1) {
                tl
                .to(".slider-mask", 0.75, {
                    width: "100%",
                    ease: Power2.easeIn,
                })

                .to(slides[i], 0, {
                    autoAlpha:1,
                })

                .to(slides[i], 0, {
                    autoAlpha: 0,
                })

                .from(slides[i + 1], 0.1, {
                    autoAlpha:0,
                })

                .to(".slider-mask", 0.75, {
                    width:0,
                    transformOrigin: "0% 50%",
                    ease: Power2.easeIn,
                })
                .addPause()
            };
        };
        function updateControls(){
            var prev = $('#prev-btn')
            var next = $('#next-btn')
            var slides = ClientSlider.find('.slide');
            var slideIndex = slides.index(ClientSlider.find('.slide.current-slide'));
            prev.show();
            next.show();
            if(slideIndex == 0){
                prev.fadeOut();
            }
            if(slideIndex == slides.length - 1){
                next.fadeOut();
            }
        }
        updateControls();
        function nextSlide(){
            var slides = ClientSlider.find('.slide');
            var slideIndex = slides.index(ClientSlider.find('.slide.current-slide'));
            slides.removeClass('current-slide');
            $(slides[slideIndex + 1]).addClass('current-slide');
            
            tl.play();
            updateControls();
            
        }
        function previousSlide(){
            var slides = ClientSlider.find('.slide');
            var slideIndex = slides.index(ClientSlider.find('.slide.current-slide'));
            slides.removeClass('current-slide');
            $(slides[slideIndex - 1]).addClass('current-slide');

            tl.reverse();
            updateControls();
        }
        function GO(e) {
            var ScrollDirection = isNaN(e) ? e.wheelDelta || -e.detail : e;
            (ScrollDirection < 0) ? nextSlide() : previousSlide();
        };

        document.getElementById('next-btn').addEventListener("click", function () {
            GO(-1);

        });
        document.getElementById('prev-btn').addEventListener("click", function () {
            GO(1);
        });

        ////// Client Slider Parallax
        if ($(window).width() >= 960) {
            var controller = new ScrollMagic.Controller({ globalSceneOptions: {triggerHook: "onEnter"}});

            var tween = new TimelineMax()
            .add([
                TweenMax.fromTo(".client-slider-info", 1, { y: "-20px" }, { y: "200px", ease: Linear.easeNone }),
            ]);
            
            var scene = new ScrollMagic.Scene({ triggerElement: "#client-slider", duration: "400%" })
                .setTween(tween)
                //.addIndicators()
                .addTo(controller);
        }
        
    }

    ////// HP Testimonial Parallax
    if ($('#testimonial-container').length > 0) {
        
        var controller = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: "onEnter" } });

        var tween = new TimelineMax()
            .add([
                TweenMax.fromTo("#testimonial-container", 1, { y: "-25%" }, { y: "90%", ease: Linear.easeNone }),
            ]);

        var scene = new ScrollMagic.Scene({ triggerElement: "#hp-testimonial-block", duration: "400%" })
            .setTween(tween)
            //.addIndicators()
            .addTo(controller);
    }


    ////// HP Testimonial Parallax
    if ($('#testimonial-container').length > 0) {

        var controller = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: "onEnter"
            }
        });

        var tween = new TimelineMax()
            .add([
                TweenMax.fromTo("#testimonial-container", 1, {
                    y: "-25%"
                }, {
                    y: "90%",
                    ease: Linear.easeNone
                }),
            ]);

        var scene = new ScrollMagic.Scene({
                triggerElement: "#hp-testimonial-block",
                duration: "400%"
            })
            .setTween(tween)
            //.addIndicators()
            .addTo(controller);
    }

    //Services Icon Parallax
    $('.info-block').each(function () {
        var controller = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: "onEnter",
                duration: "200%"
            }
        });
        var currentServiceIcon = $(this);
        var scene = new ScrollMagic.Scene({
                triggerElement: currentServiceIcon[0]
            })
            .setTween(currentServiceIcon.find('.info-block-icon'), {
                y: "-200%",
                ease: Linear.easeNone
            })
            //.addIndicators()
            .addTo(controller);
    });


    // Team Icon Parallax
    $('.team-member').each(function () {
        var controller = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: "onEnter",
                duration: "200%"
            }
        });
        var currentServiceIcon = $(this);
        var scene = new ScrollMagic.Scene({
            triggerElement: currentServiceIcon[0]
        })
        .setTween(currentServiceIcon.find('.team-member-icon'), {
            y: "-100%",
            ease: Linear.easeNone
        })
        .addTo(controller);
    });

    ////// Back to top button and fade

    $("#back-to-top").click(function (e) {
        e.preventDefault();
        $('.page-container').animate({
            scrollTop: 0
        }, 2000);
    });

    var controller = new ScrollMagic.Controller();

    var scene = new ScrollMagic.Scene({
        triggerElement: "#back-to-top-trigger"
    })
    .setTween("#back-to-top", 1, { autoAlpha:1 }) // trigger a TweenMax.to tween
    .addTo(controller);

    

    // var tl = new TimelineMax({
    //     repeat: -1
    // }); /* repeat loop forever */
    // tl.to(".dark-banner", 30, {
    //     backgroundPosition: "-2247px 0px",
    //     /* negative width of background image your animating - left top */
    //     ease: Linear.easeNone /* make sure you use Linear.easeNone so its smooth */
    // });



    $(function () {
        // scroll is still position
        var scroll = $(document).scrollTop();
        var headerHeight = $('.main-header').outerHeight();

        $(window).scroll(function () {
            // scrolled is new position just obtained
            var scrolled = $(document).scrollTop();

            // optionally emulate non-fixed positioning behaviour

            if (scrolled > headerHeight) {
                $('.main-header').addClass('nav-up');
            } else {
                $('.main-header').removeClass('nav-up');
            }

            if (scrolled > scroll) {
                // scrolling down
                $('.main-header').removeClass('nav-down');
            } else {
                //scrolling up
                $('.main-header').addClass('nav-down');
            }

            scroll = $(document).scrollTop();
        });


    });

    
    $("#scroll-down-page").click(function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: ($('#scroll-to-hp-element').offset().top)
        }, 500);
    });


    $(".scroll-to-section").click(function (e) {
        var element = $(this).attr("data-scroll");
        e.preventDefault();
        $('html, body').animate({
            scrollTop: ($(element).offset().top)
        }, 500);
    });


    /// play video
    if ($('.video').length > 0) {
        $('.video').each(function () {
            $(this)[0].play();
        });
    }


    if ($('#coffee-cta-img').length > 0) {
    var coffee = new TimelineMax();

    coffee.fromTo($("#coffee-cta-img"), 20, {
        left: "100%"
    }, {
        left: "0%"
    }, 0);

    var scene = new ScrollMagic.Scene({
            triggerElement: "#cta-coffee",
            duration: "200%"
        })
        .setTween(coffee)
        .addTo(controller)
    }

    if ($('#hp-banner-trigger').length > 0) {
        var mcText = new TimelineMax();

        mcText.fromTo($("#hp-banner"), 20, {
            backgroundPosition: "400% 90%"
        },{
            backgroundPosition: "0% 90%"
        },
        0);

        var scene = new ScrollMagic.Scene({
                triggerElement: "#hp-banner-trigger",
                duration: "200%"
            })
            .setTween(mcText)
            .addTo(controller)
            //.addIndicators()
    }


    if ($('#campaign-work-trigger').length > 0) {
        
        var campaignText = new TimelineMax();

        campaignText.fromTo($("#campaign-work-slider"), 20, {
            backgroundPosition: "0% 0%"
        },{
            backgroundPosition: "90% 0%"
        },
        0);

        var scene = new ScrollMagic.Scene({
            triggerElement: "#campaign-work-trigger",
            duration: "200%"
        })
        .setTween(campaignText)
        .addTo(controller)
        //.addIndicators()
    }

    if ($('#campaign-work-slider').length > 0) {

        // get height of all text blocks except last and add together to create pinned duration.
        var outerHeight = 0;
        $('.work-scroller-text-block:not(:last-child)').each(function () {
            outerHeight += $(this).outerHeight();
        });

        var scene = new ScrollMagic.Scene({
            triggerElement: ".how-it-works-slider-enter-trigger",
            duration: outerHeight
        })
        .setPin(".how-it-works-slider-img")
        //.addIndicators()
        .addTo(controller);


        ////////

        $('.work-scroller-text-block').each(function (index) {

            var workBlockTrigger = "#" + $(this).attr('id');
            var workBlockItems = "#" + $(this).attr('data-img');
            var divHeight = $(this).outerHeight();

            var campaignWork1 = new TimelineMax({
                paused:true
            });

            var campaignWork1Colour = new TimelineMax();
            var campaignWork1Img = new TimelineMax();

            campaignWork1Colour.fromTo($(workBlockItems + " .colour-block-inner"), 1, {
                bottom:"100%",
                ease: Power4.easeIn
            }, {
                bottom:"0%",
                ease: Power4.easeIn
            }, 0.5);

            campaignWork1Img.fromTo($(workBlockItems + " .work-scroller-img"), 0.5, {
                opacity:0,
                ease: Power4.easeIn,
            }, {
                opacity: 1,
                ease: Power4.easeIn
            }, 0);

            campaignWork1.add(campaignWork1Colour).add(campaignWork1Img)

            var scene = new ScrollMagic.Scene({
                triggerElement:  workBlockTrigger,
                duration: divHeight
            })
            //.addIndicators()
            .addTo(controller)

            .on('enter', function () {
                campaignWork1.play();
            })

            .on('leave', function (e) {
                if (index === (length) && e.scrollDirection === "FORWARD") {
                    console.log('end')
                } else {
                    campaignWork1.reverse(); 
                }
            });

            


        });


    

    }

    $(".custom-file input").on("change", function () {
        var fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });

    $('.agile-diagram-number').slick({
        fade: true,
        arrows: false,
        infinite: false,
        draggable:false
    });

    $('.agile-slider').slick({
        fade:true,
        infinite: false,
        asNavFor: '.agile-diagram-number',
        appendArrows:'.agile-slider-arrows',
        nextArrow: "<div class='agile-diagram-next'><img src='/images/slick-next-arrow.svg'></div>",
        prevArrow: "<div class='agile-diagram-prev'><img src='/images/slick-prev-arrow.svg'></div>",
    });

    if ($('.agile-diagram-wheel-img').length > 0) {

        var agileWheel = new TimelineMax();

        agileWheel.fromTo($(".agile-diagram-wheel-img"), 20, {
            rotation:0
        }, {
            rotation:45
        },
        0);

        var scene = new ScrollMagic.Scene({
            triggerElement: ".agile-section",
            duration: "200%"
        })
        .setTween(agileWheel)
        .addTo(controller)
    }

    $('a[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.agile-slider').slick('slickGoTo', slideno - 1);
    });

    var ctrl = new ScrollMagic.Controller({
        globalSceneOptions: {
            triggerHook: "onEnter",
            duration: "200%"
        }
    });

    // $('.fifty-fifty-img').each(function (index) {

    //     var fiftyFiftyTrigger = "#" + $(this).attr('id');
    //     var fiftyFiftyItems = "#" + $(this).attr('data-item');

    //     new ScrollMagic.Scene({
    //         triggerElement: fiftyFiftyTrigger
    //     })
    //     .setTween(fiftyFiftyItems, {
    //         y: "-130px",
    //         ease: Linear.easeNone
    //     })
    //     //.addIndicators()
    //     .addTo(ctrl);
    // });

        // Example starter JavaScript for disabling form submissions if there are invalid fields
    (function() {
        'use strict';
        window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
            }, false);
        });
        }, false);
    })();
    
    $( "#hp-banner-trigger" ).html(function (i, html) {
        return html.replace(/(\w+\s\w+)/, '<span>$1</span>')
    });
    

});




